@tailwind base;
@tailwind components;
@tailwind utilities;

/* Clases personalizadas para fuentes */
.font-arial {
  font-family: 'Arial Rounded MT Bold', sans-serif;
}

.font-baloo {
  font-family: 'Baloo 2', cursive;
}

/* Aplicar fuentes a elementos específicos */
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-arial;
}

body,
p,
span,
div {
  @apply font-baloo;
}

body {
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.shadow-bottom {
  @apply shadow-md border-b border-gray-300;
}

img {
  @apply max-w-full block h-auto;
}

.img-top-navbar {
  @apply h-12;
  /* Tailwind utility for 50px height */
}

.title-card {
  @apply text-black text-xl font-normal leading-tight;
  /* Adjusted for readability */
}

.subtitle-name {
  @apply text-gray-500 font-normal leading-snug;
}

.subtitle-value {
  @apply text-black text-right font-normal leading-snug;
}

.links-text-size {
  @apply text-sm;
}

.indicator {
  @apply mx-auto p-0 list-none relative w-96 h-44 overflow-hidden;
  /* Adjusted width and height with Tailwind utilities */
}

.indicator *,
.indicator::before {
  box-sizing: border-box;
}

.indicator::before,
.indicator::after {
  @apply absolute;
}

.indicator::before {
  content: "";
  @apply w-full h-full bg-cover;
  background-image: url("./assets/indicator.png");
}

.indicator span.bar {
  @apply absolute w-full h-full border-solid border-t-0;
  top: 100%;
  left: 0;
  border-width: 0 0 45px 0;
  border-radius: 0 0 175px 175px;
  transform-origin: 50% 0;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  z-index: 4;
  border-color: transparent;
  animation-name: rotate-one;
}

.indicator span.result {
  @apply absolute left-1/2 bottom-0 transform -translate-x-1/2 text-xl font-bold text-cadetblue;
  /* Tailwind utility for color */
}

span.result span:nth-child(1) {
  @apply text-2xl;
}

@keyframes rotate-one {
  0% {
    transform: rotate(0);
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .title-card {
    @apply text-lg;
    /* Smaller text size for tablets */
  }

  .subtitle-name,
  .subtitle-value {
    @apply text-base;
    /* Adjusted text size for tablets */
  }

  .img-top-navbar {
    @apply h-10;
    /* Adjusted height for tablets */
  }

  .indicator {
    @apply w-64 h-36;
    /* Adjusted size for tablets */
  }

  .indicator span.result {
    @apply text-lg;
    /* Smaller text size for result on tablets */
  }

  span.result span:nth-child(1) {
    @apply text-xl;
    /* Smaller text size for result on tablets */
  }
}

@media (max-width: 480px) {
  .title-card {
    @apply text-base;
    /* Smaller text size for mobile devices */
  }

  .subtitle-name,
  .subtitle-value {
    @apply text-sm;
    /* Adjusted text size for mobile devices */
  }

  .img-top-navbar {
    @apply h-8;
    /* Adjusted height for mobile devices */
  }

  .indicator {
    @apply w-56 h-28;
    /* Adjusted size for mobile devices */
  }

  .indicator span.result {
    @apply text-base;
    /* Smaller text size for result on mobile devices */
  }

  span.result span:nth-child(1) {
    @apply text-lg;
    /* Smaller text size for result on mobile devices */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.cluster-marker {
  animation: fadeIn 0.5s ease-out;
}

.gm-svpc,
.gmnoprint,
.gm-control-active {
  border-radius: 15px !important;
}

.gmnoprint>div,
.gm-style-mtc-bbw {
  overflow: hidden !important;
}


.badges-container-2 {
  background-color: rgb(77, 54, 138);
  padding: 10px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 10px;
  z-index: 9;
}

.gradient-border {
  --borderWidth: 5px;
  position: relative;
  border-radius: 15px;
}

.gradient-border::before {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #efb810, #ffffff, #ffdd87, #ffbf00, #ffffff);
  border-radius: calc(2 * var(--borderWidth));
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  -o-background-clip: padding-box;
  -ms-background-clip: padding-box;
  z-index: -1;
  animation: animatedgradient 2s ease alternate infinite;
  background-size: 300% 300%;
  border-radius: 15px;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 1s ease-in-out;
}

@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-up {
  animation: fade-up 0.5s ease-in-out;
}


@keyframes fade-left {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-20px);
  }
}

.animate-fade-left {
  animation: fade-left 0.5s ease-in-out;
}

.hex {
  display: inline-block;
  filter: url('#goo');
  width: 100%;
  position: relative;
}

.hex::before {
  content: "";
  display: block;
  background: currentColor;
  padding-top: 90%;
  /* 100%*cos(30) */
  transform: rotate(90deg);
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.pos0 {
  top: 0%;
  left: 30%;
}

.pos1 {
  top: 17%;
  left: 0%;
}

.pos2 {
  top: 17%;
  left: 60%;
}

.pos3 {
  top: 34%;
  left: 30%;
}

.pos4 {
  top: 51%;
  left: 0%;
}

.pos5 {
  top: 68%;
  left: 30%;
}

.pos6 {
  top: 51%;
  left: 60%;
}

/* Global CSS */
@keyframes waveAnimation {
  0% {
    transform: scale(0.3);
    opacity: 1;
  }

  70% {
    transform: scale(2.5);
    opacity: 0.3;
  }

  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.wave {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 3px solid rgb(77, 53, 138);
  /* Color azul con transparencia */
  border-radius: 50%;
  opacity: 0;
  animation: waveAnimation 4.5s infinite cubic-bezier(0.4, 0, 0.2, 1);
  /* Animación más suave */
}

.wave1 {
  animation-delay: 0.5s;
}

.wave2 {
  animation-delay: 1s;
}

.wave3 {
  animation-delay: 1.5s;
}

.custom-dot-list {
  margin-bottom: 15px !important;
}

.custom-dot-list li {
  margin: 0 5px;
}

.custom-dot-list li button {
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  border: none;
}


.custom-dot-list li button:hover,
.custom-dot-list li button:focus,
li.react-multi-carousel-dot--active button {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.contenedor_mapa>div>div>div {
  border: 0 !important;
}